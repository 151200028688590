<template>
  <complianceComponent status="all" />
</template>

<script>
import complianceComponent from "../../components/compliance/complianceList.vue";

export default {
  components: {
    complianceComponent,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
